import * as React from 'react';
import {ComponentType, ReactNode} from 'react';
import styled from 'styled-components';
import {AnchorLink, AnchorLinkProps} from "gatsby-plugin-anchor-links";
import {getDebug} from "../utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";

export type LinkProps = AnchorLinkProps & {
    variant?: "inheritStyle" | "linkStyle";
    style?: any;
    rel?: any;
    target?: any;
    className?: string;
};
export type OutlinkProps = {
    to: string;
    children: ReactNode;
}

const debug = getDebug(__filename);
const StyledOutlink = styled(OutboundLink)`
  color: inherit;
  text-decoration: inherit;
`;
const ExternalLinks: {[k in LinkProps['variant']]: ComponentType<OutlinkProps>} = {
    inheritStyle: styled(Outlink)`
      text-decoration: inherit;
      color: inherit;
    `,
    linkStyle: styled(Outlink)`
      color: inherit;
      text-decoration: underline;
    `
};
const InternalLinks: {[k in LinkProps['variant']]: ComponentType<AnchorLinkProps>} = {
    inheritStyle: styled(AnchorLink)`
      text-decoration: inherit;
      color: inherit;
    `,
    linkStyle: styled(AnchorLink)`
      color: inherit;
      text-decoration: underline;
    `
};

export function Link({variant , ...props}: LinkProps) {
    variant = variant || "inheritStyle";
    if (isOutlink(props.to)) {
        const {to, children} = props;
        const Link = ExternalLinks[variant];
        return <Link {...props} {...{to, children}} />;

    } else {
        const Link = InternalLinks[variant];
        return <Link {...props} />;
    }
}
export function isOutlink(url: string) {
    return url.startsWith('http://') || url.startsWith('https://');
}
export function Outlink({to, children, ...props}: OutlinkProps) {
    return <StyledOutlink {...props} target={"_blank"} href={to}>{children}</StyledOutlink>
}


