import * as React from 'react';
import {CSSProperties, useEffect, useRef, useState} from 'react';
import isEqual from 'lodash.isequal';
import CircularJSON from 'circular-json';
import _debug, {IDebugger} from "debug";
import {WindowLocation} from "@reach/router";
import {PageProps} from "gatsby";

export type IntrinsicElementProps = {
    id?: string;
    className?: string;
    style?: CSSProperties;
}

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useHasChanged<T>(value: T, compare: string | ((a: T, b: T) => boolean) = isEqual): boolean {
    const previous = usePrevious(value);
    if (typeof compare === 'string') {
        return previous && value && (previous?.[compare] === value?.[compare]);
    } else {
        return !compare(previous, value);
    }
}

export function renderPrettyJSON(json: any, props: any = {}) {
    return <pre {...props}>{CircularJSON.stringify(json, null, 2)}</pre>;
}

export function tsxTyped<V>(v: V) {
    return v;
}


type DebugType = IDebugger & {
    group: (name: string, ...args: any[]) => void;
    groupError: (name: string) => void;
    groupLog: (...args: any[]) => void;
    groupEnd: () => void;
}

export function getDebug(ref = "__NO_REF__") {
    const i = ref.indexOf('/monitoring-ui/');
    if (i >= 0) {
        ref = ref.slice(i + 15);
    }
    const dbg = _debug(ref) as DebugType;
    if (dbg.enabled) {
        // @ts-ignore
        dbg.group = (name, ...args) => console.groupCollapsed(`${name} %c (${ref})`, ...args, 'color: #bbb');
        dbg.groupError = (name) => console.group(`%c ${name}`, 'color: red');
        dbg.groupLog = (...args) => console.log(...args);
        dbg.groupEnd = () => console.groupEnd();
    } else {
        dbg.group = () => {
        };
        dbg.groupError = () => {
        };
        dbg.groupLog = () => {
        };
        dbg.groupEnd = () => {
        };
    }

    return dbg;
}

export const isBrowser = () => typeof window !== "undefined"


export function getPlayStoreUrl() {
    const params = isBrowser() ? new URLSearchParams(window.location.search) : new Map();
    const utm_source = params.get('utm_source') || `unprn.com${isBrowser() ? window.location.pathname : ''}`;
    const utm_medium = params.get('utm_medium') || `unprn-site`;
    const utm_campaign = params.get('utm_campaign') || ``;

    return `https://play.google.com/store/apps/details?id=com.unprn.flutter_beta&utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'`;
}
